export const tabOptions = [
  "Unmatched Trading Experience",
  "Sharpen your edge",
  "Smart Liquidity ",
];

export const slide2SubText = [
  "On-chain trading reimagined. Retain full custody of your assets and leverage the speed of zero-knowledge proofs on zkSync Era.",
  "Trade like a pro, even if you’re not.",
  "Liquidity provisioning made easy. Take complete control of your liquidity or let the protocol do the work for you. The choice is yours.",
];

export const cardContent = {
  0: [
    {
      heading: "Minimal Slippage",
      subheading: "Trade with size and experience little to no slippage.",
      wordsToChangeColor: 1,
      videoFile: "grid-card1.mp4",
      videoFileWebm: "grid-card1.webm",
      bgImage: "/images/card-1.png",
    },
    {
      heading: "High Leverage",
      subheading:
        "Amplify your trading potential with up to 50x leverage on RFX.",
      wordsToChangeColor: 1,
      videoFile: "grid-card2.mp4",
      videoFileWebm: "grid-card2.webm",
      bgImage: "/images/card-2.png",
    },
    {
      heading: "Best Pricing For Trades",
      subheading:
        "RFXs dual oracle set up ensures optimal pricing for trades and protects traders from liquidation risk during periods of volatility.",
      wordsToChangeColor: 2,
      videoFile: "grid-card3.mp4",
      videoFileWebm: "grid-card3.webm",
      bgImage: "/images/card-3.png",
    },
    {
      heading: "1-click Trading",
      subheading:
        "Set up an RFX smart account and benefit from streamlined execution and superior trading UX.",
      wordsToChangeColor: 1,
      videoFile: "grid-card4.mp4",
      videoFileWebm: "grid-card4.webm",
      bgImage: "/images/card-4.png",
    },
  ],
  1: [
    {
      heading: "Native Pair Trades",
      subheading:
        "Create your own cross-pair and unlock new arbitrage and basis trading opportunities.",
      wordsToChangeColor: 2,
      videoFile: "grid-card1.mp4",
      videoFileWebm: "grid-card1.webm",
      bgImage: "/images/card-1.png",
    },
    {
      heading: "SDK",
      subheading:
        "Efficient market execution for institutions and high frequency traders with a wide range of trading strategies and preferences.",
      wordsToChangeColor: 1,
      videoFile: "grid-card2.mp4",
      videoFileWebm: "grid-card2.webm",
      bgImage: "/images/card-2.png",
    },
    {
      heading: "Pro / Lite UI",
      subheading:
        "Tailor your trading experience. Maximise signal, optimise for execution and filter out noise.",
      wordsToChangeColor: 3,
      videoFile: "grid-card3.mp4",
      videoFileWebm: "grid-card3.webm",
      bgImage: "/images/card-3.png",
    },
    {
      heading: "Cross margin",
      subheading:
        "Manage your risk and unlock maximum capital efficiency by utilising your entire portfolio as margin on RFX.",
      wordsToChangeColor: 1,
      videoFile: "grid-card4.mp4",
      videoFileWebm: "grid-card4.webm",
      bgImage: "/images/card-4.png",
    },
  ],
  2: [
    {
      heading: "Maximum flexibility",
      subheading:
        "Segregated Pools for each market offer targeted exposure. Shared Pool minimizes liquidity fragmentation across markets. Dual design gives LPs greater flexibility.",
      wordsToChangeColor: 1,
      videoFile: "grid-card1.mp4",
      videoFileWebm: "grid-card1.webm",
      bgImage: "/images/card-1.png",
    },
    {
      heading: "Shared Liquidity Layer",
      subheading:
        "Liquidity is allocated algorithmically across selected markets based on pre-defined strategies to increase capital utilization and APRs for LPs. Earn best in class yields and benefit from RFXs novel protocol design.",
      wordsToChangeColor: 2,
      videoFile: "grid-card2.mp4",
      videoFileWebm: "grid-card2.webm",
      bgImage: "/images/card-2.png",
    },
    {
      heading: "Liquidity Vaults",
      subheading:
        "Define your own strategy and provide concentrated liquidity to specific markets. Complete control over your liquidity enhances capital efficiency, utilisation, and APRs. Simple to set up and easy to use.",
      wordsToChangeColor: 1,
      videoFile: "grid-card3.mp4",
      videoFileWebm: "grid-card3.webm",
      bgImage: "/images/card-3.png",
    },
    {
      heading: "LP Insurance",
      subheading:
        "Remain protected 24x7 and obtain coverage against several types of DeFi specific and smart contract risks, underwritten by the RFX insurance fund in conjunction with third-party entities.",
      wordsToChangeColor: 1,
      videoFile: "grid-card4.mp4",
      videoFileWebm: "grid-card4.webm",
      bgImage: "/images/card-4.png",
    },
  ],
};

export const SLIDE_SWITCH_TIMER = 1000 * 15;
