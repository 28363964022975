const Paper = () => {
  return (
    <img
      src="/images/paper.png"
      className="absolute w-full h-full top-0 left-0 opacity-20 mix-blend-soft-light select-none pointer-events-none"
      alt=""
    />
  );
};

export default Paper;
