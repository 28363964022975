export function truncatePrice(price) {
  const priceAfterDecimal = price.toFixed(20).split(".")[1];

  let truncatedPrice = "";

  let loopCounter = 0;
  let nonZeroCounter = 0;

  while (loopCounter < priceAfterDecimal.length && nonZeroCounter < 2) {
    if (
      nonZeroCounter !== 0 &&
      Number(priceAfterDecimal.charAt(loopCounter)) === 0
    ) {
      break;
    }

    truncatedPrice += priceAfterDecimal.charAt(loopCounter);

    if (Number(priceAfterDecimal.charAt(loopCounter)) !== 0) {
      nonZeroCounter++;
    }

    loopCounter++;
  }

  if (nonZeroCounter === 0) return "0";

  return `0.${truncatedPrice}`;
}
