import { useEffect, useState } from "react";

export const BackgroundVideoPlayer = ({
  webMVideo,
  mp4Video,
  videoOptions,
}) => {
  const [isWebMSupported, setIsWebMSupported] = useState(null);

  useEffect(() => {
    if (!webMVideo) return;
    const video = document.createElement("video");
    const isSupported =
      video.canPlayType('video/webm; codecs="vp8, vorbis"') === "probably";
    setIsWebMSupported(isSupported);
  }, [webMVideo]);

  return (
    <>
      <video
        autoPlay
        loop
        muted
        playsInline
        src={isWebMSupported && webMVideo ? webMVideo : mp4Video}
        {...videoOptions}
      />
    </>
  );
};
