import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useScramble } from "use-scramble";
import {
  SLIDE_SWITCH_TIMER,
  cardContent,
  slide2SubText,
  tabOptions,
} from "../../config/slide2-tabs";
import { MotionInView } from "../MotionInView";
import Slide2CardContainer from "../Slide2CardContainer";
import { varFadeInUp } from "../variants";

const Slide2 = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const hoveringRef = useRef(false);
  const timeoutRef = useRef();
  const [headingRef, isHeadingInView] = useInView({
    triggerOnce: true,
    delay: 300,
    trackVisibility: true,
  });

  const [sectionRef, isSectionInView] = useInView({
    delay: 300,
    trackVisibility: true,
  });

  const { ref: slide2Heading, replay: playSlide2Heading } = useScramble({
    text: "The first derivatives exchange with smart liquidity",
    scramble: 3,
    speed: 0.4,
  });

  useEffect(() => {
    if (isHovering) return;
    timeoutRef.current = setTimeout(() => {
      if (isSectionInView)
        setActiveTab((prev) =>
          hoveringRef.current
            ? prev
            : prev === tabOptions.length - 1
            ? 0
            : prev + 1
        );
    }, SLIDE_SWITCH_TIMER);

    if (!isSectionInView) clearTimeout(timeoutRef.current);
  }, [isSectionInView, activeTab, isHovering]);

  useEffect(() => {
    if (isHovering) clearTimeout(timeoutRef.current);
    hoveringRef.current = isHovering;
  }, [isHovering]);

  useEffect(() => {
    if (isSectionInView) playSlide2Heading();
  }, [isSectionInView]);

  return (
    <section ref={sectionRef} className="relative  h-auto xl:h-screen">
      <div className="pt-12 xl:pt-40 w-full">
        <div className="px-6 xl:px-[120px] mb-4 xl:mb-6" ref={headingRef}>
          <MotionInView
            variants={varFadeInUp}
            className="flex flex-col items-center"
          >
            <p
              className="text-greyscale-text-primary font-tt-commons-pro font-bold text-2xl xl:text-[56px] xl:leading-[50px] tracking-[1.92px] xl:tracking-[4.48px] text-center mb-4"
              style={{ fontVariantCaps: "small-caps" }}
              ref={slide2Heading}
            />

            <p className="text-ccc xl:text-greyscale-text-secondary text-base tracking-[1.28px] capitalize font-normal">
              {slide2SubText[activeTab]}
            </p>
          </MotionInView>
        </div>
        <div className="px-6 xl:px-[120px]">
          <div className="h-auto xl:h-[60px] box-border flex flex-col xl:flex-row mb-8 xl:mb-12">
            {tabOptions.map((tab, idx) => (
              <div
                key={tab}
                className={clsx(
                  "px-6 min-w-fit uppercase font-semibold border-b h-full flex items-center justify-center transition-all duration-300 xl:flex-1 py-6 xl:py-0",
                  tabOptions[activeTab] === tab
                    ? "text-greyscale-text-primary border-greyscale-text-primary"
                    : "border-border-primary text-greyscale-grey-secondary cursor-pointer"
                )}
                onClick={() => setActiveTab(idx)}
                onMouseOver={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                {tab}
              </div>
            ))}
          </div>

          <Slide2CardContainer cards={cardContent[activeTab]} />
        </div>
      </div>
    </section>
  );
};

export default Slide2;
