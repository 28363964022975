import { useInView } from "react-intersection-observer";
import SectionHeading from "../SectionHeading";
import { varFadeInUp, varFadeIn } from "../variants";
import { MotionInView } from "../MotionInView";
import { motion } from "framer-motion";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

const mirrorData = [
  {
    title: "RFX Interstellar Voyage: Mainnet Launch",
    mobileImgUrl: "/images/blog-2.png",
    imgUrl: "/images/blog-2.png",
    date: "October 9th, 2024",
    description:
      "The long-awaited moment is finally here. Season 1 of RFXs Interstellar Voyage commences on Oct 14, 2024.",
    link: "https://mirror.xyz/0x12Daf0085914D71fEA20Ccf66cb4704b67498383/vuoutxaYElLlCaDRDhn_z3PrtIaVpKTUJ4GM9orG8_Q",
  },
  {
    title: "RFX Interstellar Voyage: Contributor Manifesto Part 1",
    mobileImgUrl: "/images/blog-1.png",
    imgUrl: "/images/blog-1.png",
    date: "October 1st, 2024",
    description:
      "As RFX gears up for its upcoming main-net launch, there's a few important announcements we wanted to make. ",
    link: "https://mirror.xyz/0x12Daf0085914D71fEA20Ccf66cb4704b67498383/DKzlUy0Hb8PCMNGKbtLHH-SrEyCjVQJBYn74lPCxCbI",
  },
  {
    title: "RFX Integrates with Zyfi - A Gasless trading experience is coming",
    mobileImgUrl: "/images/blog-3.png",
    imgUrl: "/images/blog-3.png",
    date: "September 25th, 2024",
    description:
      "RFX is excited to integrate with Zyfi, a leader in on-chain user experiences powered by zkSync.",
    link: "https://mirror.xyz/0x12Daf0085914D71fEA20Ccf66cb4704b67498383/HXGvqKz187YMBU88jThq_hTIbhzSsOPebHl79nMurug",
  },
];

const Slide6 = () => {
  const [slideRef, isSlideInView] = useInView({
    triggerOnce: true,
    delay: 500,
    trackVisibility: true,
  });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="relative min-h-[980px] lg:h-screen w-full flex justify-center items-center overflow-x-hidden">
      <img
        alt="abstract background"
        src="/images/faq-bg.png"
        className="w-full h-full absolute top-0 left-0 mix-blend-difference opacity-40 pointer-events-none"
      />
      <div
        className="w-full text-center lg:w-[1041px] 2xl:w-[1200px] 3xl:w-[1296px] flex flex-col gap-2"
        ref={slideRef}
      >
        <MotionInView variants={varFadeInUp} className="flex flex-col gap-2">
          <SectionHeading heading="UPDATES" isInView={isSlideInView} />
          <span className="capitalize mt-6 text-base font-normal leading-6 text-greyscale-text-secondary tracking-[1.28px]">
            stay tuned with RFX up to date.
          </span>
        </MotionInView>
        <MotionInView variants={varFadeInUp}>
          <div className="text-white text-base xl:text-2xl font-bold font-['TT Commons Pro'] uppercase tracking-widest text-left px-12 py-6 xl:px-0 xl:py-0">
            Mirror updates
          </div>
        </MotionInView>

        {!isMobile ? (
          <div
            variants={varFadeInUp}
            className="w-full text-left lg:mt-10 flex flex-col xl:flex-row gap-2 xl:justify-between items-center px-6 xl:px-0"
          >
            {mirrorData.map(
              ({ title, date, description, link, imgUrl }, index) => (
                <MirrorCard
                  key={`${title}-${index}`}
                  title={title}
                  imgUrl={imgUrl}
                  date={date}
                  description={description}
                  link={link}
                  isMobile={isMobile}
                />
              )
            )}
          </div>
        ) : (
          <div className="relative w-full h-[388px]">
            <CardStack isMobile={isMobile} />
          </div>
        )}

        <MotionInView variants={varFadeInUp}>
          <div className="text-white text-base xl:text-2xl font-bold font-['TT Commons Pro'] uppercase tracking-widest text-left mt-12  px-12 py-6 xl:px-0 xl:py-0">
            Media Updates
          </div>
        </MotionInView>

        <MotionInView
          variants={varFadeIn}
          className="w-full text-left flex flex-col xl:flex-row justify-between items-start pt-6"
        >
          <MediaUpdatesCard
            date={"October 9th, 2024"}
            title={"RFX Interstellar Voyage: Mainnet Launch"}
          />
          <MediaUpdatesCard
            date={"October 1st, 2024"}
            title={"RFX Interstellar Voyage: Contributor Manifesto Part 1"}
          />
          <MediaUpdatesCard
            date={"September 25th, 2024"}
            title={
              "RFX Integrates with Zyfi - A Gasless trading experience is coming"
            }
          />
        </MotionInView>
      </div>
    </div>
  );
};

const MediaUpdatesCard = ({ date, title }) => {
  return (
    <div className="w-full xl:w-[388px] h-auto flex-col justify-start items-start gap-2 inline-flex pr-6  px-12  xl:px-0 ">
      <div className="py-1.5 justify-center items-center gap-2.5 inline-flex">
        <div className="text-stone-300 text-base font-['TT Commons Pro'] leading-normal tracking-wider">
          {date}{" "}
        </div>
      </div>
      <div className="py-1.5 justify-center items-start gap-2.5 inline-flex xl:w-max ">
        <div className="text-white text-base font-bold font-['TT Commons Pro'] tracking-wider w-full xl:w-[388px]">
          {title}
        </div>
      </div>
    </div>
  );
};

const MirrorCard = ({ title, date, description, link, isMobile, imgUrl }) => {
  return (
    <div className="relative w-full h-[388px] 2xl:w-[388px] 2xl:h-[563px]  xl:w-[287px] xl:h-[508px] xl:opacity-80 rounded  flex flex-col">
      <div className="absolute w-full h-full bg-surface-modal rounded-[3px] opacity-80 select-none pointer-events-none" />
      <div className="absolute w-full h-full bg-modal-glass rounded backdrop-blur-[6px] select-none pointer-events-none" />
      <div className="absolute w-full h-full bg-noise opacity-80 rounded select-none pointer-events-none" />
      <div className="relative z-10 w-full h-full rounded-lg border border-border-primary">
        <img
          src={imgUrl}
          alt=""
          className="w-full h-[160px] xl:h-[256px] rounded-t-lg"
        />
        <div className="w-full h-[102px] p-4 lg:p-6 border-b border-zinc-800 flex-col justify-center items-start gap-2 inline-flex">
          <div className="justify-center items-center gap-2.5 inline-flex">
            <div className="text-white text-base font-bold font-['TT Commons Pro'] tracking-wider text-left uppercase">
              {title}
            </div>
          </div>
          <div className="justify-center items-center gap-2.5 inline-flex">
            <div className="text-stone-300 text-base font-['TT Commons Pro'] leading-normal tracking-wider  text-left">
              {date}
            </div>
          </div>
        </div>
        <div className="w-full xl:w-[340px] text-white text-sm font-medium font-['TT Commons Pro'] leading-normal tracking-wide p-4 lg:p-6 lg:pt-4  text-left">
          {description}
        </div>
        {!isMobile && (
          <>
            <div className=" flex-1" />
            <a href={link} target="_blank">
              <div className="text-indigo-500 text-sm xl:text-xl font-bold font-['TT Commons Pro'] tracking-widest p-6">
                Read More
              </div>
            </a>
          </>
        )}
      </div>
    </div>
  );
};

function modulo(m, n) {
  return ((m % n) + n) % n;
}

const title = ["Leaderboard", "Perpetuals", "Dashboard", "Permissionless"];

function CardStack({ isMobile }) {
  const cardsLength = mirrorData.length;
  const [front, setFront] = useState(0);
  const [swipeKeyframes, setSwipeKeyframes] = useState(0);
  function dragEnd(event, info) {
    setFront((front) => (front + 1) % cardsLength);
  }

  return (
    <div className="flex flex-col gap-2 xl:gap-0 xl:flex-row xl:justify-between xl:items-center h-[388px]">
      <motion.div>
        {mirrorData.map(
          ({ title, date, description, link, mobileImgUrl }, index) => {
            const diff = modulo(index - front, cardsLength);
            return (
              <motion.div
                // style={{ background: card }}
                key={title + index}
                initial={false}
                animate={{
                  zIndex:
                    diff === cardsLength - 1
                      ? [4 * cardsLength, 4 * (cardsLength - diff)]
                      : 4 * (cardsLength - diff),
                  scale: 1.0 - 0.09 * diff,
                  x:
                    diff === cardsLength - 1
                      ? [swipeKeyframes, 30 * diff]
                      : 30 * diff,
                  // rotate: diff === cardsLength - 1 ? [20 - 360, 0] : 0
                }}
                transition={{
                  duration: 0.5,
                  rotate: {
                    duration: 0.7,
                  },
                }}
                drag={front === index ? "x" : false}
                // whileDrag={{
                //   rotate: "-10deg",
                //   transition: {
                //     duration: 0.1,
                //   },
                // }}
                onClick={dragEnd}
                // dragSnapToOrigin
                className="!w-full !h-[388px] card   !bg[#111113] bg_noise"
              >
                <MirrorCard
                  title={title}
                  imgUrl={mobileImgUrl}
                  date={date}
                  description={description}
                  link={link}
                  isMobile={isMobile}
                />
              </motion.div>
            );
          }
        )}
      </motion.div>
    </div>
  );
}

export default Slide6;
{
  /* <div className="w-[324px] h-[388px] relative rounded-lg border border-zinc-800">
    <div className="w-[324px] h-[388px] left-0 top-0 absolute opacity-80 bg-zinc-950 rounded-[3px] backdrop-blur-md" />
    <div className="w-[324px] h-[388px] left-0 top-0 absolute bg-gradient-to-b from-slate-500 to-gray-700 rounded backdrop-blur-md" />
    <img className="w-[324px] h-[388px] left-0 top-0 absolute opacity-80 rounded" src="https://via.placeholder.com/324x388" />
    <img className="w-[324px] h-40 left-0 top-0 absolute" src="https://via.placeholder.com/324x160" />
    <div className="h-[61px] p-4 left-0 top-[160px] absolute border-b border-zinc-800 flex-col justify-center items-start gap-2 inline-flex">
        <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
            <div className="text-white text-sm font-bold font-['TT Commons Pro'] tracking-wide">introducing rfx - a next gen perp</div>
        </div>
        <div className="justify-center items-center gap-2.5 inline-flex">
            <div className="text-stone-300 text-base font-['TT Commons Pro'] leading-normal tracking-wider">February 05</div>
        </div>
    </div>
    <div className="w-[292px] left-[16px] top-[237px] absolute text-white text-xs font-medium font-['TT Commons Pro'] leading-normal tracking-wide">RFX is a cutting-edge decentralised perpetual futures platform set to revolutionise the derivatives landscape on zkSync Era. </div>
</div> */
}
