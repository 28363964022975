import { clsx } from "clsx";
import BgNoise from "./BgNoise";
import { varZoomInOut } from "./variants";
import { MotionInView } from "./MotionInView";

const GlassContainer = ({ className, children }) => {
  return (
    <MotionInView
      variants={varZoomInOut}
      className={clsx("relative glassmorphism", className)}
    >
      <BgNoise />
      {children}
    </MotionInView>
  );
};

export default GlassContainer;
