import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export const MotionInView = ({
  children,
  className,
  variants,
  transition,
  threshold,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: threshold || 0.75,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start(Object.keys(variants)[1]);
    } else {
      controls.stop(Object.keys(variants)[0]);
    }
  }, [controls, inView, variants]);

  return (
    <motion.div
      ref={ref}
      initial={Object.keys(variants)[0]}
      animate={controls}
      variants={variants}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
};
