import queryString from "query-string";
import { TOKENS } from "../config/tokens";

function buildUrl(baseUrl, path, query) {
  const qs = query ? `?${queryString.stringify(query)}` : "";

  return `${baseUrl}${path}${qs}`;
}

function createPriceData(symbol, assetData) {
  return {
    tokenSymbol: symbol,
    deltaPercentageStr: Number(assetData.CHANGEPCT24HOUR).toFixed(2) + "%",
  };
}

export async function fetch24hPrices(chainId) {
  return fetch(
    buildUrl("https://min-api.cryptocompare.com", "/data/pricemultifull", {
      fsyms: TOKENS[chainId].map((t) => t.symbol).join(","),
      tsyms: "BTC,USD",
    }),
  )
    .then((res) => res.json())
    .then((res) => {
      const priceDelta = {};

      Object.keys(res.RAW).forEach((item) => {
        if (item === "ETH") {
          priceDelta[item + "/BTC"] = createPriceData(
            item + "/BTC",
            res.RAW[item].BTC,
          );
        }
        priceDelta[item] = createPriceData(item, res.RAW[item].USD);
      });
      return priceDelta;
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      // switchOracleKeeper()
      throw e;
    });
}
