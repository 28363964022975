import { useInView } from "react-intersection-observer";
import GlassContainer from "../GlassContainer";
import SectionHeading from "../SectionHeading";
import { CLOUD_FRONT_URL } from "../../config/constants";
import { varFadeInUp } from "../variants";
import { MotionInView } from "../MotionInView";

const Slide4 = () => {
  const [slideRef, isSlideInView] = useInView({
    triggerOnce: true,
    delay: 500,
    trackVisibility: true,
  });
  return (
    <section className="w-full h-[1426px] flex justify-center items-center relative">
      <div>
        <MotionInView
          variants={varFadeInUp}
          className="flex flex-col gap-7 text-center"
          ref={slideRef}
        >
          <SectionHeading heading="RFX PRO" isInView={isSlideInView} />
          <span className="text-base font-normal leading-6 text-greyscale-text-secondary tracking-[1.28px]">
            Bloomberg Terminal meets web3
          </span>
        </MotionInView>

        <div className="hidden mt-8 lg:w-[1041px] 2xl:w-[1200px] 3xl:w-[1296px] mx-auto text-left xl:flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <GlassContainer>
              <div className="xl:w-[578px] 2xl:w-[728px] 3xl:w-[778px] h-[517.48px] border border-border-primary rounded">
                <div>
                  <div>
                    <span className="border-b w-full block border-border-primary text-main text-[20px] px-6 py-5 tracking-[1.6px] leading-normal font-bold">
                      ONE SCREEN FOR ALL YOUR TRADING NEEDS
                    </span>
                    <p className="text-sm text-greyscale-text-primary font-medium leading-[18px] p-6">
                      Obtain the complete picture of the cryptocurrency market
                      on a single screen with RFX PRO. No more juggling multiple
                      screens and tabs—access all the essential data and
                      insights you need in one seamless view.
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between px-6">
                  <div className="w-full h-[340px] bg-surface-primary flex justify-center items-center">
                    <video
                      src={CLOUD_FRONT_URL + "/videos/complete.mp4"}
                      controls={false}
                      autoPlay
                      loop
                      playsInline
                      muted
                      className="w-full h-full object-fill border border-border-primary rounded"
                    />
                  </div>
                </div>
              </div>
            </GlassContainer>

            <GlassContainer>
              <div className="lg:w-[452px] 3xl:w-[493px] h-[517.48px] border border-border-primary rounded">
                <div className="flex items-center justify-between p-4  h-[348px]">
                  <video
                    src={CLOUD_FRONT_URL + "/videos/candles.mp4"}
                    controls={false}
                    autoPlay
                    loop
                    playsInline
                    muted
                    className="w-full h-full object-fill border border-border-primary rounded"
                  />
                </div>

                <div>
                  <div>
                    <span className="uppercase border-y w-full block border-border-primary text-main text-[20px] px-4 py-4 tracking-[1.6px] leading-normal font-bold">
                      Multi-Charts
                    </span>
                    <p className="text-sm text-greyscale-text-primary font-medium leading-[18px] p-4 tracking-[1.12px]">
                      Easily monitor all of your favorite cryptocurrencies with
                      real-time (live) charts & price data. Be the first to see
                      the market moves and get ahead of the competition with
                      RFX.
                    </p>
                  </div>
                </div>
              </div>
            </GlassContainer>
          </div>

          <div className="flex items-center justify-between">
            <GlassContainer>
              <div className="xl:w-[578px] 2xl:w-[728px] 3xl:w-[778px] h-[525px] border border-border-primary rounded">
                <div>
                  <span className="uppercase border-b w-full block border-border-primary text-main text-base px-4 py-6 tracking-[1.6px] leading-normal font-bold">
                    Heatmaps
                  </span>
                  <p className="text-sm pb-4 text-greyscale-text-primary font-medium leading-[18px] px-4 py-2">
                    Visualize the movement of various cryptocurrencies using
                    intuitive heatmaps. Stay informed and make smarter decisions
                    by seeing market trends and fluctuations at a glance.
                  </p>
                </div>

                <div className="flex items-center justify-between px-4">
                  <div className="xl:w-[180px] 2xl:w-[228px] h-[371px] border border-border-primary bg-surface-primary">
                    <video
                      src={CLOUD_FRONT_URL + "/videos/funding-rate-heatmap.mp4"}
                      controls={false}
                      autoPlay
                      loop
                      playsInline
                      muted
                      className="w-full h-full"
                    />
                  </div>
                  <div className="xl:w-[180px] 2xl:w-[228px] h-[371px] border border-border-primary bg-surface-primary">
                    <video
                      src={CLOUD_FRONT_URL + "/videos/market-tiles.mp4"}
                      controls={false}
                      autoPlay
                      loop
                      playsInline
                      muted
                      className="w-full h-full"
                    />
                  </div>
                  <div className="bg-surface-primary xl:w-[180px] 2xl:w-[228px] h-[371px] border border-border-primary">
                    <video
                      src={CLOUD_FRONT_URL + "/videos/heatmap.mp4"}
                      controls={false}
                      autoPlay
                      loop
                      playsInline
                      muted
                      className="w-full h-full"
                    />
                  </div>
                </div>
              </div>
            </GlassContainer>
            <GlassContainer>
              <div className="lg:w-[452px] 3xl:w-[493px] h-[525px] border border-border-primary rounded">
                <div className="flex items-center justify-between p-4 h-[364px] w-full">
                  <video
                    src={CLOUD_FRONT_URL + "/videos/news.mp4"}
                    controls={false}
                    autoPlay
                    loop
                    playsInline
                    muted
                    className="w-full h-full object-fill border border-border-primary rounded"
                  />
                </div>

                <div className="border-t border-border-primary">
                  <div>
                    <span className="uppercase border-b w-full block border-border-primary text-main text-[20px] px-4 py-4 tracking-[1.6px] leading-normal font-bold">
                      News
                    </span>
                    <p className="text-sm text-greyscale-text-primary font-medium leading-[18px] p-4">
                      Connect your Telegram channels to your RFX account and
                      receive personalized news updates tailored to your
                      specific interests in the crypto markets.
                    </p>
                  </div>
                </div>
              </div>
            </GlassContainer>
          </div>
        </div>

        <div className="xl:hidden mt-8 flex flex-col gap-4">
          <MobileCard
            image={
              <div className="flex items-start justify-between pt-0 p-4 pb-2 gap-1">
                <div className="flex-1 bg-surface-primary h-[135px] overflow-hidden py-2 px-1 rounded border border-border-primary">
                  <p className="text-[8px] whitespace-nowrap pb-1 tracking-[0.64px] leading-normal text-greyscale-text-primary font-semibold">
                    Funding Rate Heatmap
                  </p>
                  <img
                    src="/images/funding-rate.png"
                    alt="news"
                    className="w-full"
                    height={96.186}
                  />
                </div>
                <div className="flex-1 bg-surface-primary h-[135px] overflow-hidden py-2 px-1 rounded border border-border-primary">
                  <p className="text-[8px] whitespace-nowrap pb-1 tracking-[0.64px] leading-normal text-greyscale-text-primary font-semibold">
                    Market Tiles
                  </p>
                  <img
                    src="/images/market-tiles.png"
                    alt="news"
                    className="w-full"
                    height={96.186}
                  />
                </div>
                <div className="flex-1 bg-surface-primary h-[135px] overflow-hidden py-2 px-1 rounded border border-border-primary">
                  <p className="text-[8px] whitespace-nowrap pb-1 tracking-[0.64px] leading-normal text-greyscale-text-primary font-semibold">
                    Heatmaps
                  </p>
                  <img
                    src="/images/heatmap.png"
                    alt="news"
                    className="w-full"
                    height={96.186}
                  />
                </div>
              </div>
            }
            title="HEATMAPS"
            description="Visualize the movement of various cryptocurrencies using intuitive heatmaps. Stay informed and make smarter decisions by seeing market trends and fluctuations at a glance."
          />

          <MobileCard
            image={
              <div className="w-full h-[121px] overflow-hidden pb-4 px-4">
                <div className="bg-surface-primary">
                  <img
                    src="/images/news.png"
                    className="w-full object-cover object-top"
                    height={121}
                    alt="news"
                  />
                </div>
              </div>
            }
            title="News, Tailored by YOU"
            description="Connect your Telegram channels to your RFX account and receive personalized news updates tailored to your specific interests in the crypto markets."
          />

          <MobileCard
            image={
              <div className="w-full px-4 h-[152px] flex justify-between">
                <img src="/images/multichart-1.png" alt="news" />
                <img src="/images/multichart-2.png" alt="news" />
              </div>
            }
            title="Multi-Charts"
            description="Easily monitor all of your favorite cryptocurrencies with real-time (live) charts & price data. Be the first to see the market moves and get ahead of the competition with RFX."
          />

          <MobileCard
            image={
              <div className="w-full px-4 h-[152px] flex justify-center overflow-hidden">
                <div className="bg-surface-primary border border-border-primary rounded">
                  <img src="/images/picture-markets.png" alt="news" />
                </div>
              </div>
            }
            title="The Complete Picture of Markets, in 1 Screen"
            description="Obtain the complete picture of the cryptocurrency market on a single screen with RFX PRO. No more juggling multiple screens and tabs—access all the essential data and insights you need in one seamless view."
          />
        </div>
      </div>
    </section>
  );
};

const MobileCard = ({ image, title, description }) => {
  return (
    <GlassContainer className="w-[342px] border border-border-primary bg-surface-modal rounded">
      <div>
        <div className="text-left">
          <span className="border-b w-full block border-border-primary text-main text-sm p-4 tracking-[1.12px] leading-normal font-bold">
            {title}
          </span>
          <p className="text-xs pb-4 text-greyscale-text-primary font-medium leading-[18px] px-4 py-2">
            {description}
          </p>
        </div>
      </div>

      {image}
    </GlassContainer>
  );
};

export default Slide4;
