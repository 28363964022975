import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useScramble } from "use-scramble";

const SectionHeading = ({ heading, isInView }) => {
  const { ref, replay } = useScramble({
    text: heading,
    scramble: 3,
    speed: 0.4,
  });

  useEffect(() => {
    if (isInView) {
      replay();
    }
  }, [isInView]);

  return (
    <span
      ref={ref}
      className="font-tt-commons-pro font-bold sm:text-[56px] tracking-[3.2px] text-greyscale-text-primary text-2xl"
    />
  );
};

export default SectionHeading;
