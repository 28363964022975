export const changeFirstWordsColor = (text, numWords) => {
  let words = text.split(" ");

  if (words.length === 0) {
    return text;
  }

  for (let i = 0; i < numWords && i < words.length; i++) {
    words[i] = `<span style="color:#696EFF">${words[i]}</span>`;
  }

  return { __html: words.join(" ") };
};
