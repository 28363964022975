import { useMemo } from "react";
import { usePythPrices } from "../hooks/usePythPrices";
import TokenTickerItem from "./TokenTickerItem";

const TokenTicker = () => {
  const { pricesData } = usePythPrices(300);

  const tokenData = useMemo(() => {
    if (!pricesData) return [];

    return Object.values(pricesData).map((data) => ({
      symbol: data.symbol,
      expo: data.price.expo,
      price: data.price.price,
      delta: data.delta,
    }));
  }, [pricesData]);

  return (
    <div className="h-8 flex items-center gap-4 animation-infinite-ticker box-border">
      {tokenData.map((token, idx) => (
        <TokenTickerItem
          key={`${token.symbol}-${idx}`}
          symbol={token.symbol}
          currentPrice={token.price}
          expo={token.expo}
          priceDelta={token.delta}
        />
      ))}
    </div>
  );
};

export default TokenTicker;
