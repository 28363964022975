import clsx from "clsx";
import { CLOUD_FRONT_URL, STACKED_TOKEN_ICON_PAIR } from "../config/constants";
import { truncatePrice } from "../utils/formatter";

const TokenTickerItem = ({ symbol, currentPrice, priceDelta, expo }) => {
  return (
    <div className="flex items-center h-full min-w-fit">
      <div className="flex items-center gap-2 h-full">
        {STACKED_TOKEN_ICON_PAIR.includes(symbol.toLowerCase()) ? (
          <div>
            <img
              alt={symbol}
              className="relative z-20"
              src={`${CLOUD_FRONT_URL}/assets/${
                symbol.toLowerCase().split("/")[0]
              }.svg`}
              height={20}
              width={20}
            />
            <img
              alt={symbol}
              className="relative z-10 -mt-3 ml-2"
              src={`${CLOUD_FRONT_URL}/assets/${
                symbol.toLowerCase().split("/")[1]
              }.svg`}
              height={20}
              width={20}
            />
          </div>
        ) : (
          <img
            alt={symbol}
            src={`${CLOUD_FRONT_URL}/assets/${symbol.toLowerCase()}.svg`}
            height={24}
            width={24}
          />
        )}
        <div className="uppercase text-sm leading-[18px] font-semibold text-greyscale-text-primary opacity-90">
          {`${symbol.toUpperCase()}-USD`}
        </div>
        <div className="text-sm leading-[18px] text-greyscale-text-secondary opacity-90 font-medium mr-2">
          $
          {currentPrice / Math.pow(10, Math.abs(expo)) < 1
            ? truncatePrice(currentPrice / Math.pow(10, Math.abs(expo)))
            : (currentPrice / Math.pow(10, Math.abs(expo))).toFixed(2)}
        </div>
      </div>

      <div className="flex items-center h-full gap-1">
        <img
          alt={symbol}
          src={
            priceDelta.includes("-")
              ? "/icons/arrow-lose.svg"
              : "/icons/arrow-gain.svg"
          }
          height={16}
          width={16}
        />
        <span
          className={clsx(
            "text-sm font-medium leading-[18px] opacity-90",
            priceDelta.includes("-")
              ? "text-error-primary"
              : "text-success-primary",
          )}
        >
          {`${priceDelta}`}
        </span>
      </div>
    </div>
  );
};

export default TokenTickerItem;
