import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const cards = [
  `/images/slide1-perps.png`,
  `/images/slide1-leaderboard.png`,
  `/images/slide1-portfolio.png`,
  `/images/slide1-permissionless.png`,
];

function modulo(m, n) {
  return ((m % n) + n) % n;
}

const title = ["Perpetuals", "Leaderboard", "Dashboard", "Permissionless"];

function CardStack() {
  const cardsLength = cards.length;
  const [front, setFront] = useState(0);
  const [swipeKeyframes, setSwipeKeyframes] = useState(0);
  function dragEnd(event, info) {
    setFront((front) => (front + 1) % cardsLength);
  }
  const isWideScreen = useMediaQuery({ minWidth: 2000 });
  const isAbove1800Screen = useMediaQuery({ minWidth: 1800 });
  const isAbove1500Screen = useMediaQuery({ minWidth: 1560 });
  const isAbove1400Screen = useMediaQuery({ minWidth: 1400 });
  const isAbove1300Screen = useMediaQuery({ minWidth: 1280 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const getDiff = () => {
    if (isWideScreen) {
      return 200;
    }
    if (isAbove1800Screen) {
      return 190;
    }
    if (isAbove1500Screen) {
      return 180;
    }
    if (isAbove1400Screen) {
      return 120;
    }

    if (isAbove1300Screen) {
      return 80;
    }

    return 30;
  };

  return (
    <div className="flex flex-col gap-2 xl:gap-0 xl:flex-row xl:justify-between xl:items-center h-full">
      <motion.div>
        <img
          alt="arrow"
          className="cursor-pointer absolute top-[15%] left-2 lg:left-6 lg:top-[45%] rotate-180 z-20"
          height={24}
          src="/icons/right-arrow.svg"
          width={24}
          onClick={dragEnd}
        />
        <img
          alt="arrow"
          className="cursor-pointer absolute top-[15%] right-0 lg:right-6 lg:top-[45%] z-30"
          height={24}
          src="/icons/right-arrow.svg"
          width={24}
          onClick={dragEnd}
        />
        {cards.map((card, index) => {
          const diff = modulo(index - front, cardsLength);
          return (
            <motion.div
              // style={{ background: card }}
              key={card + index}
              initial={false}
              animate={{
                zIndex:
                  diff === cardsLength - 1
                    ? [4 * cardsLength, 4 * (cardsLength - diff)]
                    : 4 * (cardsLength - diff),
                scale: 1.0 - 0.09 * diff,
                x:
                  diff === cardsLength - 1
                    ? [swipeKeyframes, getDiff() * diff]
                    : getDiff() * diff,
                // rotate: diff === cardsLength - 1 ? [20 - 360, 0] : 0
              }}
              transition={{
                duration: 0.5,
                rotate: {
                  duration: 0.7,
                },
              }}
              drag={front === index ? "x" : false}
              // whileDrag={{
              //   rotate: "-10deg",
              //   transition: {
              //     duration: 0.1,
              //   },
              // }}
              onClick={dragEnd}
              // dragSnapToOrigin
              className="card 4xl:!w-[1051px] 4xl:!h[741px]"
            >
              <div className="h-full w-full bg-modal-glass p-4">
                <div className="h-full w-full">
                  <img
                    src={card}
                    alt="rfx"
                    className={`h-full w-full pointer-events-none select-none`}
                  />
                </div>
              </div>
            </motion.div>
          );
        })}
      </motion.div>
      {isMobile ? (
        <div className="text-white text-base pt-40 xl:pt-0 xl:text-[32px] 5xl:text-[64px] font-semibold tracking-[2.56px] xl:pr-20">
          {title[front]}
        </div>
      ) : (
        <div className="text-white text-base absolute top-[15%] right-0 lg:right-6 lg:top-[45%] z-20 xl:text-[32px] 5xl:text-[64px] font-semibold tracking-[2.56px] xl:pr-20 ">
          {title[front]}
        </div>
      )}
    </div>
  );
}

export default CardStack;
