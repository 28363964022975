import { EvmPriceServiceConnection } from "@pythnetwork/pyth-evm-js";
import { DEFAULT_CHAIN_ID } from "../config/tokens";
import {
  NATIVE_TOKEN_ADDRESS,
  TOKENS,
  getWrappedToken,
} from "../config/tokens";
import { useQuery } from "@tanstack/react-query";
import { ethers, toBigInt } from "ethers";
import { fetch24hPrices } from "../utils/fetcher";

const bigNumberify = (n) => {
  try {
    return toBigInt(n);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("bigNumberify error", e);
    return undefined;
  }
};

function expandDecimals(n, decimals) {
  return bigNumberify(n).mul(bigNumberify(10).pow(decimals));
}

function parseContractPrice(price, tokenDecimals) {
  return price.mul(expandDecimals(1, tokenDecimals));
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const address = [
  "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43", //btc
  "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace", //eth
  "0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d", //sol
  "0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b", //usdt
  "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a", //usdc
  "0xb0948a5e5313200c632b51bb5ca32f6de0d36e9950a942d19751e833f70dabfd", //dai
  "0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4", //pepe
  "0xc96458d393fe9deb7a7d63a0ac41e2898a67a7750dbd166673279e06c868df0a", //ETH/BTC
  "0x9d4294bbcd1174d6f2003ec365831e64cc31d9f6f15a2b85399db8d5000960f6",
];

const tokens = TOKENS[DEFAULT_CHAIN_ID];

let mapPythId = {};
tokens.forEach((token) => {
  if (token.pythId) mapPythId = { [token.pythId]: token, ...mapPythId };
});

export const usePythPrices = (chainId = DEFAULT_CHAIN_ID) => {
  const { data, refetch } = useQuery({
    queryKey: ["galeto-oracle-price", mapPythId],
    queryFn: async () => {
      try {
        const connection = new EvmPriceServiceConnection(
          "https://hermes.pyth.network",
          { verbose: true },
        );

        const priceDelta = await fetch24hPrices(chainId);

        const latestFeed = await connection.getLatestPriceFeeds(
          Object.keys(mapPythId),
        );
        let priceInfo;
        latestFeed.forEach((result) => {
          const tokenInfo = mapPythId["0x" + result.id];
          const price = result.price;
          priceInfo = {
            [tokenInfo?.address]: {
              price,
              ...tokenInfo,
              delta: priceDelta[tokenInfo.symbol].deltaPercentageStr,
            },
            ...priceInfo,
          };
        });

        return { res: priceInfo, updatedAt: latestFeed[0].price.publishTime };
      } catch (err) {
        console.log(err);
        return { res: undefined, updatedAt: undefined };
      }
    },

    refetchInterval: 50000,
    enabled: Boolean(true),
  });

  return {
    pricesData: data?.res,
    updatedAt: data?.updatedAt,
    refetchGaletoOraclePrice: refetch,
  };
};
