import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { useScramble } from "use-scramble";
import { CLOUD_FRONT_URL } from "../../config/constants";
import { BackgroundVideoPlayer } from "../BackgroundVideoPlayer";
import { MotionInView } from "../MotionInView";
import { varFadeInUp, varZoomInOut } from "../variants";
import VectorBackground from "../VectorBackground";

const carouselConfig = {
  0: {
    title: "Select token pair",
    subHeading: "Risk Analysis is done on your chosen pairs",
  },
  1: {
    title: (
      <>
        Quantitative Markets
        <br /> Parametrization
      </>
    ),
    subHeading: "Provide a name and liquidity to your pool",
  },
  2: {
    title: "Deploy and Fund the Pool",
    subHeading: "Recheck all the input information and confirm",
  },
};

const Slide3 = () => {
  const video = useRef(null);
  const [targetRef, isInView] = useInView();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [carouselSlide, setCarouselSlide] = useState(0);
  const [translateSlide, setTranslateSlide] = useState("translateX(0%)");

  const changeCarouselSlide = (step) => {
    if (step === carouselSlide) return;

    const translatePercent = step * 100;

    setTranslateSlide(`translateX(-${translatePercent}%)`);
    setCarouselSlide(step);
  };

  const [slide3Ref, isSlide3InView] = useInView({
    triggerOnce: true,
    delay: 500,
    trackVisibility: true,
  });

  const { ref: slide3Heading, replay: playSlide3Heading } = useScramble({
    text: "Permissionless markets",
    scramble: 3,
    speed: 0.4,
  });

  const playVideo = () => {
    video.current.currentTime = 0;
    video.current.play();
  };

  useEffect(() => {
    if (isSlide3InView) {
      playSlide3Heading();
    }
  }, [isSlide3InView]);

  useEffect(() => {
    isInView && playVideo();
  }, [carouselSlide, isInView]);

  return (
    <section className=" h-auto xl:min-h-screen w-full overflow-hidden px-6 xl:px-[120px] py-12 xl:py-[120px] flex flex-col">
      <div className="w-full max-w-[1296px] mx-auto z-50">
        <div ref={slide3Ref} className={clsx(isMobile && "px-8")}>
          <MotionInView variants={varFadeInUp}>
            <h1
              ref={slide3Heading}
              className="text-2xl font-tt-commons-pro font-bold text-greyscale-text-primary tracking-[1.92px] xl:text-[40px] xl:tracking-[3.2px] 2xl:text-[56px] 2xl:tracking-[4.48px] mb-8"
              style={{ fontVariant: "small-caps" }}
            />
          </MotionInView>
          <MotionInView variants={varFadeInUp}>
            <p className="font-tt-commons-pro capitalize tracking-[1.28px] leading-6 text-base text-ccc xl:text-greyscale-text-secondary">
              Create and trade new markets seamlessly, driving innovation
              without restrictions.
            </p>
          </MotionInView>
        </div>
      </div>
      <div className="w-full max-w-[1296px] mx-auto">
        <MotionInView variants={varZoomInOut}>
          {isMobile ? (
            <div ref={targetRef} className="mt-14">
              <VectorBackground>
                <div className="w-full h-full flex justify-center items-center">
                  <div className="overflow-hidden flex justify-center items-center w-[327px] h-[408px]">
                    <div
                      className="flex [&_video]:rounded-xl transition-all duration-300"
                      style={{
                        transform: translateSlide,
                      }}
                    >
                      <BackgroundVideoPlayer
                        webMVideo={`${CLOUD_FRONT_URL}/videos/step-1.webm`}
                        mp4Video={`${CLOUD_FRONT_URL}/videos/step-1.mp4`}
                        videoOptions={{
                          ref: carouselSlide === 0 ? video : null,
                          onEnded: () => changeCarouselSlide(1),
                          controls: false,
                          loop: false,
                          autoPlay: false,
                        }}
                      />
                      <BackgroundVideoPlayer
                        webMVideo={`${CLOUD_FRONT_URL}/videos/step-2.webm`}
                        mp4Video={`${CLOUD_FRONT_URL}/videos/step-2.mp4`}
                        videoOptions={{
                          ref: carouselSlide === 1 ? video : null,
                          onEnded: () => changeCarouselSlide(2),
                          controls: false,
                          loop: false,
                          autoPlay: false,
                        }}
                      />

                      <BackgroundVideoPlayer
                        webMVideo={`${CLOUD_FRONT_URL}/videos/step-4.webm`}
                        mp4Video={`${CLOUD_FRONT_URL}/videos/step-4.mp4`}
                        videoOptions={{
                          ref: carouselSlide === 2 ? video : null,
                          onEnded: () => changeCarouselSlide(0),
                          controls: false,
                          loop: false,
                          autoPlay: false,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </VectorBackground>

              <div className="flex flex-col items-start gap-4 relative mt-6">
                <CarouselButton
                  slideNumber={Number(carouselSlide)}
                  heading={carouselConfig[carouselSlide].title}
                  subHeading={carouselConfig[carouselSlide].subHeading}
                  activeSlideNumber={carouselSlide}
                  handleSlideChange={() =>
                    changeCarouselSlide(Number(carouselSlide))
                  }
                  isMobile
                />
              </div>
            </div>
          ) : (
            <div
              ref={targetRef}
              className="flex items-center justify-between mt-12 gap-14"
            >
              <div className="flex flex-col items-start gap-6 flex-1 z-20">
                {Object.keys(carouselConfig).map((key) => (
                  <CarouselButton
                    key={key}
                    slideNumber={Number(key)}
                    heading={carouselConfig[key].title}
                    subHeading={carouselConfig[key].subHeading}
                    activeSlideNumber={carouselSlide}
                    handleSlideChange={() => changeCarouselSlide(Number(key))}
                  />
                ))}
              </div>

              <div className="flex flex-1">
                <VectorBackground>
                  <div className="w-full h-full flex justify-center items-center">
                    <div className="overflow-hidden">
                      <div
                        className="w-[353px] h-[441px] flex [&_video]:rounded-xl transition-all duration-300"
                        style={{
                          transform: translateSlide,
                        }}
                      >
                        <BackgroundVideoPlayer
                          webMVideo={`${CLOUD_FRONT_URL}/videos/step-1.webm`}
                          mp4Video={`${CLOUD_FRONT_URL}/videos/step-1.mp4`}
                          videoOptions={{
                            ref: carouselSlide === 0 ? video : null,
                            onEnded: () => changeCarouselSlide(1),
                            controls: false,
                            loop: false,
                            autoPlay: false,
                          }}
                        />
                        <BackgroundVideoPlayer
                          webMVideo={`${CLOUD_FRONT_URL}/videos/step-2.webm`}
                          mp4Video={`${CLOUD_FRONT_URL}/videos/step-2.mp4`}
                          videoOptions={{
                            ref: carouselSlide === 1 ? video : null,
                            onEnded: () => changeCarouselSlide(2),
                            controls: false,
                            loop: false,
                            autoPlay: false,
                          }}
                        />
                        <BackgroundVideoPlayer
                          webMVideo={`${CLOUD_FRONT_URL}/videos/step-4.webm`}
                          mp4Video={`${CLOUD_FRONT_URL}/videos/step-4.mp4`}
                          videoOptions={{
                            ref: carouselSlide === 2 ? video : null,
                            onEnded: () => changeCarouselSlide(0),
                            controls: false,
                            loop: false,
                            autoPlay: false,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </VectorBackground>
              </div>

              {/* <div className="new-markets-glass flex flex-1 items-center justify-center relative overflow-hidden">
                <div className="new-markets-glass-separator-1" />
                <div className="new-markets-glass-separator-2" />

                <img
                  alt=""
                  src="/images/vector-background.png"
                  className="absolute w-full h-full top-0 left-0 opacity-20"
                />
                <div className="overflow-hidden">
                  <div
                    className="w-[353px] h-[441px] flex [&_video]:rounded-xl transition-all duration-300"
                    style={{
                      transform: translateSlide,
                    }}
                  >
                    <BackgroundVideoPlayer
                      webMVideo={`${CLOUD_FRONT_URL}/videos/step-1.webm`}
                      mp4Video={`${CLOUD_FRONT_URL}/videos/step-1.mp4`}
                      videoOptions={{
                        ref: carouselSlide === 0 ? video : null,
                        onEnded: () => changeCarouselSlide(1),
                        controls: false,
                        loop: false,
                      }}
                    />
                    <BackgroundVideoPlayer
                      webMVideo={`${CLOUD_FRONT_URL}/videos/step-2.webm`}
                      mp4Video={`${CLOUD_FRONT_URL}/videos/step-2.mp4`}
                      videoOptions={{
                        ref: carouselSlide === 1 ? video : null,
                        onEnded: () => changeCarouselSlide(2),
                        controls: false,
                        loop: false,
                      }}
                    />
                    <BackgroundVideoPlayer
                      webMVideo={`${CLOUD_FRONT_URL}/videos/step-4.webm`}
                      mp4Video={`${CLOUD_FRONT_URL}/videos/step-4.mp4`}
                      videoOptions={{
                        ref: carouselSlide === 2 ? video : null,
                        onEnded: () => changeCarouselSlide(0),
                        controls: false,
                        loop: false,
                      }}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </MotionInView>
      </div>
    </section>
  );
};

const CarouselButton = ({
  slideNumber,
  heading,
  subHeading,
  activeSlideNumber,
  handleSlideChange,
}) => {
  return (
    <div
      className={`w-full flex gap-4 cursor-pointer xl:pb-3 xl:border-b border-border-primary text-left ${
        slideNumber !== activeSlideNumber && "opacity-40"
      }`}
      onClick={handleSlideChange}
    >
      <div className="text-greyscale-text-secondary text-2xl font-bold tracking-[1.92px]">
        0{slideNumber + 1}
      </div>
      <div className="space-y-1.5">
        <div className="text-xl xl:text-2xl text-greyscale-text-primary font-bold tracking-[1.6px] xl:tracking-[1.92px]">
          {heading}
        </div>
        <div className="text-base tracking-[1.28px] text-ccc">{subHeading}</div>
      </div>
    </div>
  );
};

export default Slide3;
