import { cookie3Analytics } from "@cookie3/analytics";
import { createContext, useContext } from "react";

const config = {
  siteId: 687,
  staging: process.env.REACT_APP_VERCEL_ENV !== "production",
};

const analytics = cookie3Analytics(config);

const Cookie3Context = createContext(analytics);

export const Cookie3Provider = ({ children }) => {
  return (
    <Cookie3Context.Provider value={analytics}>
      {children}
    </Cookie3Context.Provider>
  );
};

export const useCookie3 = () => {
  const context = useContext(Cookie3Context);

  return context;
};
