import { useEffect } from "react";
import BgNoise from "../components/BgNoise";
import Navbar from "../components/Navbar";
import Paper from "../components/Paper";
import {
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
} from "../components/Slides";
import { useCookie3 } from "../lib/analytics/provider";
import { BackgroundVideoPlayer } from "../components/BackgroundVideoPlayer";
import { CLOUD_FRONT_URL } from "../config/constants";

export const HomePage = () => {
  const cookie3 = useCookie3();

  useEffect(() => {
    cookie3.trackPageView();
  }, []);

  return (
    <div className="bg-landing-Linear overflow-x-hidden">
      <BgNoise />
      <Paper />
      <Navbar />
      <Slide1 />
      <Slide2 />
      <div className="relative w-full h-full">
        <BackgroundVideoPlayer
          webMVideo={`${CLOUD_FRONT_URL}/videos/bg2-1.mov`}
          mp4Video={`${CLOUD_FRONT_URL}/videos/bg2-1.mov`}
          videoOptions={{
            className:
              "absolute -top-10 h-full w-full 4xl:w-[1563px] 4xl:h-full !left-0 object-fill pointer-events-none select-none",
          }}
        />
        <Slide3 />
        <Slide4 />
      </div>
      <Slide5 />
      <Slide6 />
      <Slide7 />
    </div>
  );
};
