import { ZeroAddress } from "ethers";
const AVALANCHE = 43114;
const AVALANCHE_FUJI = 43113;
const ARBITRUM = 42161;
const ARBITRUM_GOERLI = 421613;
const ARBITRUM_SEPOLIA = 421614;
const ZKSYNC_SEPOLIA = 300;
export const DEFAULT_CHAIN_ID = ZKSYNC_SEPOLIA;

const AddressZero = ZeroAddress;

export const NATIVE_TOKEN_ADDRESS = AddressZero;

export const TOKENS = {
  [ARBITRUM_GOERLI]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl:
        "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: "0xCcF73F4Dcbbb573296BFA656b754Fe94BB957d62",
      isShortable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bitcoin",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0xCcF73F4Dcbbb573296BFA656b754Fe94BB957d62",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x04FC936a15352a1b15b3B9c56EA002051e3DB3e5",
      isStable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0x04FC936a15352a1b15b3B9c56EA002051e3DB3e5",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xBFcBcdCbcc1b765843dCe4DF044B92FE68182a62",
      isStable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0xBFcBcdCbcc1b765843dCe4DF044B92FE68182a62",
    },
    {
      name: "Dai",
      symbol: "DAI",
      address: "0x7b7c6c49fA99b37270077FBFA398748c27046984",
      decimals: 18,
      isStable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dai",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0x7b7c6c49fA99b37270077FBFA398748c27046984",
    },
    {
      name: "Solana",
      symbol: "SOL",
      decimals: 18,
      address: "0x9A98a11279FaeB0fF695dFEC3C4B8a29138d0a2f",
      isSynthetic: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/4128/small/solana.png?1640133422",
      coingeckoUrl: "https://www.coingecko.com/en/coins/solana",
    },
    {
      name: "Test token",
      symbol: "TEST",
      decimals: 18,
      address: "0x13C52ccB49fE3228356D0C355641961646A0D9B2",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
    },
    {
      name: "BNB",
      symbol: "BNB",
      isSynthetic: true,
      decimals: 18,
      address: "0xa076E6db62f61bd1A4fC283F84739D2b0c80e2a3",
      coingeckoUrl: "https://www.coingecko.com/en/coins/binancecoin",
    },
    {
      name: "Cardano",
      symbol: "ADA",
      decimals: 18,
      priceDecimals: 4,
      address: "0x5F8a8f06da2848f846A2b5e3e42A4A2eEC5f337B",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/cardano",
    },
    {
      name: "TRON",
      symbol: "TRX",
      decimals: 18,
      priceDecimals: 4,
      address: "0x7a9Ba06548D0499f6Debf97809CC351c1e85795D",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tron",
    },
    {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      priceDecimals: 4,
      address: "0xd98D28787F5598749331052f952196428F61e3aD",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/polygon",
    },
    {
      name: "Polkadot",
      symbol: "DOT",
      decimals: 18,
      address: "0x7361D58cBc6495B6419397dFd5ebE2e2017F23E9",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/polkadot",
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      priceDecimals: 3,
      address: "0x6DEbb9cC48819941F797a2F0c63f9168C19fD057",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/uniswap",
    },
    {
      name: "Dogecoin",
      symbol: "DOGE",
      isSynthetic: true,
      isShortable: true,
      decimals: 8,
      priceDecimals: 4,
      address: "0x3e2fA75b78edF836299127FBAA776304B4712972",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      priceDecimals: 3,
      address: "0x55602A94239a7926D92da5C53Fb96E80372382aa",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/chainlink",
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 6,
      priceDecimals: 4,
      address: "0xF1C2093383453831e8c90ecf809691123116dAaC",
      isSynthetic: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/44/small/xrp-symbol-white-128.png?1605778731",
      coingeckoUrl: "https://www.coingecko.com/en/coins/xrp",
    },
    {
      name: "GMX",
      symbol: "GMX",
      address: "",
      decimals: 18,
      imageUrl:
        "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
      isPlatformToken: true,
    },
    {
      name: "Escrowed GMX",
      symbol: "esGMX",
      address: "",
      decimals: 18,
      isPlatformToken: true,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: "",
      decimals: 18,
      imageUrl:
        "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
      isPlatformToken: true,
    },
    {
      name: "GMX Market tokens",
      symbol: "RM",
      address: "<market-token-address>",
      decimals: 18,
      imageUrl:
        "https://raw.githubusercontent.com/gmx-io/gmx-assets/main/GMX-Assets/PNG/GM_LOGO.png",
      isPlatformToken: true,
    },
  ],
  [ARBITRUM_SEPOLIA]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x980B62Da83eFf3D4576C647993b0c1D7faf17c73",
      isNative: true,
      isShortable: true,
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x980B62da83eff3d4576c647993b0c1d7faf17c73",
      isWrapped: true,
      baseSymbol: "ETH",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: "0xCcF73F4Dcbbb573296BFA656b754Fe94BB957d62",
      isShortable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bitcoin",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0xCcF73F4Dcbbb573296BFA656b754Fe94BB957d62",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x72eEBe8109E6Ee74c3499512dD2Bc49Ecd870C5B",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0x04FC936a15352a1b15b3B9c56EA002051e3DB3e5",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xBFcBcdCbcc1b765843dCe4DF044B92FE68182a62",
      isStable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0xBFcBcdCbcc1b765843dCe4DF044B92FE68182a62",
    },
    {
      name: "Dai",
      symbol: "DAI",
      address: "0x7b7c6c49fA99b37270077FBFA398748c27046984",
      decimals: 18,
      isStable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dai",
      explorerUrl:
        "https://goerli.arbiscan.io/address/0x7b7c6c49fA99b37270077FBFA398748c27046984",
    },
    {
      name: "Solana",
      symbol: "SOL",
      decimals: 18,
      address: "0x9A98a11279FaeB0fF695dFEC3C4B8a29138d0a2f",
      isSynthetic: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/4128/small/solana.png?1640133422",
      coingeckoUrl: "https://www.coingecko.com/en/coins/solana",
    },
    {
      name: "Test token",
      symbol: "TEST",
      decimals: 18,
      address: "0x13C52ccB49fE3228356D0C355641961646A0D9B2",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
    },
    {
      name: "BNB",
      symbol: "BNB",
      isSynthetic: true,
      decimals: 18,
      address: "0xa076E6db62f61bd1A4fC283F84739D2b0c80e2a3",
      coingeckoUrl: "https://www.coingecko.com/en/coins/binancecoin",
    },
    {
      name: "Cardano",
      symbol: "ADA",
      decimals: 18,
      priceDecimals: 4,
      address: "0x5F8a8f06da2848f846A2b5e3e42A4A2eEC5f337B",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/cardano",
    },
    {
      name: "TRON",
      symbol: "TRX",
      decimals: 18,
      priceDecimals: 4,
      address: "0x7a9Ba06548D0499f6Debf97809CC351c1e85795D",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tron",
    },
    {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      priceDecimals: 4,
      address: "0xd98D28787F5598749331052f952196428F61e3aD",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/polygon",
    },
    {
      name: "Polkadot",
      symbol: "DOT",
      decimals: 18,
      address: "0x7361D58cBc6495B6419397dFd5ebE2e2017F23E9",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/polkadot",
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      priceDecimals: 3,
      address: "0x6DEbb9cC48819941F797a2F0c63f9168C19fD057",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/uniswap",
    },
    {
      name: "Dogecoin",
      symbol: "DOGE",
      isSynthetic: true,
      isShortable: true,
      decimals: 8,
      priceDecimals: 4,
      address: "0x3e2fA75b78edF836299127FBAA776304B4712972",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      priceDecimals: 3,
      address: "0x55602A94239a7926D92da5C53Fb96E80372382aa",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/chainlink",
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 6,
      priceDecimals: 4,
      address: "0xF1C2093383453831e8c90ecf809691123116dAaC",
      isSynthetic: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/44/small/xrp-symbol-white-128.png?1605778731",
      coingeckoUrl: "https://www.coingecko.com/en/coins/xrp",
    },
    {
      name: "GMX",
      symbol: "GMX",
      address: "",
      decimals: 18,
      imageUrl:
        "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
      isPlatformToken: true,
    },
    {
      name: "Escrowed GMX",
      symbol: "esGMX",
      address: "",
      decimals: 18,
      isPlatformToken: true,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: "",
      decimals: 18,
      imageUrl:
        "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
      isPlatformToken: true,
    },
    {
      name: "GMX Market tokens",
      symbol: "RM",
      address: "<market-token-address>",
      decimals: 18,
      imageUrl:
        "https://raw.githubusercontent.com/gmx-io/gmx-assets/main/GMX-Assets/PNG/GM_LOGO.png",
      isPlatformToken: true,
    },
  ],
  [ZKSYNC_SEPOLIA]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      id: "ethereum",
      address: AddressZero,
      pythId:
        "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      isNative: true,
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      isV1Available: true,
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      id: "ethereum",
      decimals: 18,
      address: "0x138DCa29f0dF3492bce88f1E897a23b04c161913",
      pythId:
        "0x9d4294bbcd1174d6f2003ec365831e64cc31d9f6f15a2b85399db8d5000960f6",
      isWrapped: true,
      baseSymbol: "ETH",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      isV1Available: true,
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      id: "bitcoin",
      pythId:
        "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      // assetSymbol: 'WBTC',
      decimals: 8,
      address: "0x393049F868575b9AE9Fc5bbFa028D5Fa30Dd9760",
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      explorerUrl:
        "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      isV1Available: true,
    },
    {
      name: "Wrapped SOL (Wormhole)",
      symbol: "SOL",
      id: "solana",
      pythId:
        "0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
      decimals: 9,
      address: "0x0Cedf7c3e40847393CeFD0C51aDb515916F2e745",
      coingeckoUrl: "https://www.coingecko.com/en/coins/solana",
      explorerUrl:
        "https://arbiscan.io/token/0x2bCc6D6CdBbDC0a4071e48bb3B969b06B3330c07",
    },
    {
      name: "Bridged USDC (USDC.e)",
      symbol: "USDC.e",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      explorerUrl:
        "https://arbiscan.io/token/0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isV1Available: true,
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x9B2bcE861E8455AD88eaBAD7C73ce96edD663C7c",
      pythId:
        "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
      isStable: true,
      isV1Available: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      explorerUrl:
        "https://arbiscan.io/address/0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0x81E8CC7F05dBd6BcE8014A4bc3b2C7DA11148Fcf",
      pythId:
        "0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b",
      isStable: true,
      explorerUrl:
        "https://arbiscan.io/address/0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      isV1Available: true,
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0x2b3558FDf3A2a1c4733eb1F9e226C37E367F6E72",
      pythId:
        "0xb0948a5e5313200c632b51bb5ca32f6de0d36e9950a942d19751e833f70dabfd",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dai",
      explorerUrl:
        "https://arbiscan.io/token/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isV1Available: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      id: "bitcoin",
      address: "0x47904963fc8b2340414262125aF798B9655E58Cd",
      pythId:
        "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      isSynthetic: true,
      decimals: 8,
      explorerUrl:
        "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bitcoin",
    },
    {
      name: "PEPE",
      symbol: "PEPE",
      id: "pepe",
      address: "0x1d39C107308B3C963Cf0305B17710450AFFe0C5E",
      pythId:
        "0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4",
      isSynthetic: true,
      decimals: 18,
      explorerUrl:
        "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bitcoin",
    },
    {
      name: "ETH/BTC",
      symbol: "ETH/BTC",
      address: "0x6EC99bf62159BE5199C24179E404B4B4406bFC6B",
      pythId:
        "0xc96458d393fe9deb7a7d63a0ac41e2898a67a7750dbd166673279e06c868df0a",
      isSynthetic: true,
      decimals: 18,
      explorerUrl:
        "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bitcoin",
    },
    {
      name: "INJ",
      symbol: "INJ",
      decimals: 8,
      priceDecimals: 4,
      address: "0x00FF6962e7A5799e1497CEcc915453929C4E3EE5",
      pythId:
        "0x7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8",
      address: "0x09b73DBd5D4655C8A25C54Ed5DDAb5C99a344f60",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "TON",
      symbol: "TON",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x8963217838ab4cf5cadc172203c1f0b763fbaa45f346d8ee50ba994bbcac3026",
      address: "0x14872Af9b26401d806155cC6ccbf0e94E1661f1B",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x5de33a9112c2b700b8d30b8a3402c103578ccfa2765696471cc672bd5cf6ac52",
      address: "0x1d1ED9D008824DAb1B91B8b7E10D2a039fd2a600",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "MKR",
      symbol: "MKR",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x9375299e31c0deb9c6bc378e6329aab44cb48ec655552a70d4b9050346a30378",
      address: "0x30c21B5Ff66BEa11e274C906d52ece45de6A32CF",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "OP",
      symbol: "OP",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x385f64d993f7b77d8182ed5003d97c60aa3361f3cecfe711544d2d59165e9bdf",
      address: "0x32504e654AfA2D9bbe4FA764494D59DB41FcA73a",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "ADA",
      symbol: "ADA",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x2a01deaec9e51a579277b34b122399984d0bbf57e2458a7e42fecd2829867a0d",
      address: "0x345aa06a27A6aa0141650a740962135a6A6af4A0",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "ATOM",
      symbol: "ATOM",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xb00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819",
      address: "0x34d9EaF6739736Dc10474FCe2C1B4c0E43732E0d",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "PENDLE",
      symbol: "PENDLE",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x9a4df90b25497f66b1afb012467e316e801ca3d839456db028892fe8c70c8016",
      address: "0x367c72025644cD2D210f91794a1A03167957FeaF",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "NEAR",
      symbol: "NEAR",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xc415de8d2eba7db216527dff4b60e8f3a5311c740dadb233e13e12547e226750",
      address: "0x38bAFFA92FDb8903c8E01668B65DE01E41069c2c",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "SHIB",
      symbol: "SHIB",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xf0d57deca57b3da2fe63a493f4c25925fdfd8edf834b20f93e1f84dbd1504d4a",
      address: "0x391d855188177AA2854130641Fb5dA6263016bd0",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7",
      address: "0x408e768f7A425c11eA3dcec2ce5986B1583e7f8E",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "RUNE",
      symbol: "RUNE",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x5fcf71143bb70d41af4fa9aa1287e2efd3c5911cee59f909f915c9f61baacb1e",
      address: "0x4128a0050D71a4c0838b883f422c1d62299287BF",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "BLUR",
      symbol: "BLUR",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x856aac602516addee497edf6f50d39e8c95ae5fb0da1ed434a8c2ab9c3e877e9",
      address: "0x5033b488652Cbca67D28C104F6577966e808B636",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "DOT",
      symbol: "DOT",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xca3eed9b267293f6595901c734c7525ce8ef49adafe8284606ceb307afa2ca5b",
      address: "0x59a2Cc9dcea35309F00F15830433Acb40383c1c3",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
      address: "0x5b9bA1964a20DA01571A000791C39B3A88AbE247",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "SUI",
      symbol: "SUI",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744",
      address: "0x64C3Dd6c99985a36E57BfA1Eb8cfedf85143d4Fa",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "GLM",
      symbol: "GLM",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x309d39a65343d45824f63dc6caa75dbf864834f48cfaa6deb122c62239e06474",
      address: "0x8b30db6D6D145aA2168e460a3f7984cE2Af9f51E",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "RNDR",
      symbol: "RNDR",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xab7347771135fc733f8f38db462ba085ed3309955f42554a14fa13e855ac0e2f",
      address: "0x96302B077B9baAA256128D2256f0D283Da6af4FE",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "FXS",
      symbol: "FXS",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x735f591e4fed988cd38df74d8fcedecf2fe8d9111664e0fd500db9aa78b316b1",
      address: "0x966336009Be2dc127Aa0f7f586D691F02CE28260",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "FET",
      symbol: "FET",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xb98e7ae8af2d298d2651eb21ab5b8b5738212e13efb43bd0dfbce7a74ba4b5d0",
      address: "0x985641eBF214B35059d5aD1f77e9008D41786dA9",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "AAVE",
      symbol: "AAVE",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x2b9ab1e972a281585084148ba1389800799bd4be63b957507db1349314e47445",
      address: "0x9C20109EF44E79f87f373fF09A05C590F4b04c3d",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "XTZ",
      symbol: "XTZ",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x0affd4b8ad136a21d79bc82450a325ee12ff55a235abc242666e423b8bcffd03",
      address: "0x9fbc78e3b6082073272524639c801b2c267c8ec0",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "UNI",
      symbol: "UNI",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x78d185a741d07edb3412b09008b7c5cfb9bbbd7d568bf00ba737b456ba171501",
      address: "0xa64FdE29FE73Cb7623e6e249F72764A7691477F1",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "IMX",
      symbol: "IMX",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x941320a8989414874de5aa2fc340a75d5ed91fdff1613dd55f83844d52ea63a2",
      address: "0xB8e40bb65Ba542b970251322c5245C935A32d53A",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "ARB",
      symbol: "ARB",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
      address: "0xBab631A77F97Cf7786c02f5981F5a048BC3254CD",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "LDO",
      symbol: "LDO",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xc63e2a7f37a04e5e614c07238bedb25dcc38927fba8fe890597a593c0b2fa4ad",
      address: "0xc168296Cf50b8207EF7200610e90c5F18bfE1E8A",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "WIF",
      symbol: "WIF",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x4ca4beeca86f0d164160323817a4e42b10010a724c2217c6ee41b54cd4cc61fc",
      address: "0xC4A8d53eC0Fc9761C9E8DA0370e35CFfd4e0AB70",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "TIA",
      symbol: "TIA",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xa4d617ccf05ffb84700b0fadff45abb4a508e122fa6fc4d7b7c478a7306aaecd",
      address: "0xcb2a2BDD3e1393d36D894DC55a19853DD7B5c773",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "PYTH",
      symbol: "PYTH",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x0bbf28e9a841a1cc788f6a361b17ca072d0ea3098a1e5df1c3922d06719579ff",
      address: "0xd4b5BFD1614D2E08E4e1ec60ee89023E19B69164",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "SEI",
      symbol: "SEI",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb",
      address: "0xD506AC7B9F46c87665cE47604bDf26BBE382f21b",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "APT",
      symbol: "APT",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x03ae4db29ed4ae33d323568895aa00337e658e348b37509f5372ae51f0af00d5",
      address: "0xdAaD681bE36d1B76A7D221E3ff23e114511DA5EB",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "ORDI",
      symbol: "ORDI",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x193c739db502aadcef37c2589738b1e37bdb257d58cf1ab3c7ebc8e6df4e3ec0",
      address: "0xFbE14760cA6152dbCE3c0464D72e7F044196044a",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "GRT",
      symbol: "GRT",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x4d1f8dae0d96236fb98e8f47471a366ec3b1732b47041781934ca3a9bb2f35e7",
      address: "0xfBe2906A3961D255bEf23FDE7f1C73ea15af5AAB",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "FIL",
      symbol: "FIL",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0x150ac9b959aee0051e4091f0ef5216d941f590e1c5e7f91cf7635b5c11628c0e",
      address: "0xff66d3eFF5D80c1E983967F7295D7f397a7fEe63",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "Dogecoin",
      symbol: "DOGE",
      decimals: 8,
      priceDecimals: 4,
      pythId:
        "0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
      address: "0xeeEFAbDD0430e63450F55D03daF12090D98a83df",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "Litecoin",
      symbol: "LTC",
      decimals: 8,
      pythId:
        "0x6e3f3fa8253588df9326580180233eb791e03b443a3ba7a1d892e73874e19a54",
      address: "0xF088c42803D094ca9c0D40DCC9CCD428FEF14DD7",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/litecoin",
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 6,
      priceDecimals: 4,
      pythId:
        "0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8",
      address: "0xc14e065b0067dE91534e032868f5Ac6ecf2c6868",
      coingeckoUrl: "https://www.coingecko.com/en/coins/xrp",
      isSynthetic: true,
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      priceDecimals: 3,
      pythId:
        "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      coingeckoUrl: "https://www.coingecko.com/en/coins/arbitrum",
      explorerUrl:
        "https://arbiscan.io/token/0x912ce59144191c1204e64559fe8253a0e49e6548",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      priceDecimals: 3,
      pythId:
        "0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
      address: "0x24637242DB75308a08B1061aE600cBb2a97c8DD5",
      isStable: false,
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/chainlink",
      explorerUrl:
        "https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      priceDecimals: 3,
      pythId:
        "0x78d185a741d07edb3412b09008b7c5cfb9bbbd7d568bf00ba737b456ba171501",
      address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      isStable: false,
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/uniswap",
      explorerUrl:
        "https://arbiscan.io/token/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    },
    {
      name: "GMX Market tokens",
      symbol: "RM",
      address: "<market-token-address>",
      decimals: 18,
      imageUrl:
        "https://raw.githubusercontent.com/gmx-io/gmx-assets/main/GMX-Assets/PNG/GM_LOGO.png",
      isPlatformToken: true,
    },
  ],
  [AVALANCHE_FUJI]: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0x1D308089a2D1Ced3f1Ce36B1FcaF815b07217be3",
      isWrapped: true,
      baseSymbol: "AVAX",
      imageUrl:
        "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
      coingeckoUrl: "https://www.coingecko.com/en/coins/avalanche",
      explorerUrl:
        "https://testnet.snowtrace.io/address/0x1D308089a2D1Ced3f1Ce36B1FcaF815b07217be3",
    },
    {
      name: "Ethereum (WETH.e)",
      symbol: "ETH",
      address: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      decimals: 18,
      isShortable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      explorerUrl:
        "https://testnet.snowtrace.io/address/0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
      decimals: 6,
      isStable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      explorerUrl:
        "https://testnet.snowtrace.io/address/0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0x50df4892Bd13f01E4e1Cd077ff394A8fa1A3fD7c",
      isStable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dai",
      explorerUrl:
        "https://testnet.snowtrace.io/address/0x50df4892Bd13f01E4e1Cd077ff394A8fa1A3fD7c",
    },
    {
      name: "Dai",
      symbol: "DAI",
      address: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
      decimals: 6,
      isStable: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dai",
      explorerUrl:
        "https://testnet.snowtrace.io/address/0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "WBTC",
      decimals: 8,
      address: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
      imageUrl:
        "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
      coingeckoUrl: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      explorerUrl:
        "https://testnet.snowtrace.io/address/0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
    },
    {
      name: "Solana",
      symbol: "SOL",
      decimals: 18,
      address: "0x137f4a7336df4f3f11894718528516edaaD0B082",
      isSynthetic: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/4128/small/solana.png?1640133422",
      coingeckoUrl: "https://www.coingecko.com/en/coins/solana",
    },
    {
      name: "Test token",
      symbol: "TEST",
      decimals: 18,
      address: "0x42DD131E1086FFCc59bAE9498D71E20E0C889B14",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
    },
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
      address: "0x110892Dd5fa73bE430c0ade694febD9a4CAc68Be",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binancecoin",
    },
    {
      name: "Cardano",
      symbol: "ADA",
      decimals: 18,
      priceDecimals: 4,
      address: "0xE64dfFF37Fa6Fe969b792B4146cEe2774Ef6e1a1",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/cardano",
    },
    {
      name: "TRON",
      symbol: "TRX",
      decimals: 18,
      priceDecimals: 4,
      address: "0x0D1495527C255068F2f6feE31C85d326D0A76FE8",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tron",
    },
    {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      priceDecimals: 4,
      address: "0xadc4698B257F78187Fd675FBf591a09f4c975240",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/polygon",
    },
    {
      name: "Polkadot",
      symbol: "DOT",
      address: "0x65FFb5664a7B3377A5a27D9e59C72Fb1A5E94962",
      decimals: 18,
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/polkadot",
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      priceDecimals: 3,
      address: "0xF62dC1d2452d0893735D22945Af53C290b158eAF",
      isSynthetic: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/uniswap",
    },
    {
      name: "Dogecoin",
      symbol: "DOGE",
      decimals: 8,
      priceDecimals: 4,
      address: "0x2265F317eA5f47A684E5B26c50948617c945d986",
      isSynthetic: true,
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      priceDecimals: 3,
      address: "0x6BD09E8D65AD5cc761DF62454452d4EC1545e647",
      isSynthetic: true,
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/chainlink",
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 6,
      priceDecimals: 4,
      address: "0xF1C2093383453831e8c90ecf809691123116dAaC",
      isSynthetic: true,
      imageUrl:
        "https://assets.coingecko.com/coins/images/44/small/xrp-symbol-white-128.png?1605778731",
      coingeckoUrl: "https://www.coingecko.com/en/coins/xrp",
    },
    {
      name: "GMX",
      symbol: "GMX",
      address: "",
      decimals: 18,
      imageUrl:
        "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
      isPlatformToken: true,
    },
    {
      name: "Escrowed GMX",
      symbol: "esGMX",
      address: "",
      decimals: 18,
      isPlatformToken: true,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: "",
      decimals: 18,
      imageUrl:
        "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
      isPlatformToken: true,
    },
    {
      name: "GMX Market tokens",
      symbol: "RM",
      address: "<market-token-address>",
      decimals: 18,
      imageUrl:
        "https://raw.githubusercontent.com/gmx-io/gmx-assets/main/GMX-Assets/PNG/GM_LOGO.png",
      isPlatformToken: true,
    },
  ],
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  WBTC: "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  USDT: "#67B18A",
  MIM: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  LINK: "#3256D6",
};

export const WRAPPED_TOKENS_MAP = {};
export const NATIVE_TOKENS_MAP = {};

const CHAIN_IDS = [
  ARBITRUM,
  ARBITRUM_GOERLI,
  AVALANCHE,
  AVALANCHE_FUJI,
  ZKSYNC_SEPOLIA,
];

export function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
  return TOKENS[chainId];
}
