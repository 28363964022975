import { useState } from "react";

const HoveredStateIcon = ({ hoverIcon, icon }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
      {isHovered ? hoverIcon : icon}
    </div>
  );
};

export default HoveredStateIcon;
