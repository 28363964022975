import React from "react";
import classes from "./VectorBackground.module.css";

const VectorBackground = ({ children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.dark_bg} />
      <div className={classes.glass} />
      <div className={classes.bg_noise} />
      <div className={classes.vector} />
      <div className={classes.separator_first} />
      <div className={classes.separator_second} />
      {children}
    </div>
  );
};

export default VectorBackground;
