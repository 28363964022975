import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { useScramble } from "use-scramble";
import { CLOUD_FRONT_URL } from "../../config/constants";
import { BackgroundVideoPlayer } from "../BackgroundVideoPlayer";
import { MotionInView } from "../MotionInView";
import { varZoomInOut } from "../variants";

const Slide5 = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [slide5Ref, isSlide5InView] = useInView({
    triggerOnce: true,
    delay: 300,
    trackVisibility: true,
  });

  const { ref: slide5Heading, replay: playSlide5Heading } = useScramble({
    text: "EXPERIENCED BUIDLERS",
    scramble: 3,
    speed: 0.4,
  });

  useEffect(() => {
    if (isSlide5InView) {
      playSlide5Heading();
    }
  }, [isSlide5InView]);

  return (
    <section className="h-screen relative -mt-0.5 min-h-[868px]">
      <BackgroundVideoPlayer
        webMVideo={`${CLOUD_FRONT_URL}/videos/slide8.webm`}
        mp4Video={`${CLOUD_FRONT_URL}/videos/slide8.mp4`}
        videoOptions={{
          className:
            "h-full w-full object-cover mix-blend-exclusion opacity-20",
        }}
      />

      <div className="h-[149px] separator absolute -top-5  z-10 w-full" />

      {isMobile ? (
        <div className="absolute top-0 space-y-8 px-6">
          <div ref={slide5Ref} className="flex flex-col gap-8 mt-[138px]">
            <span
              ref={slide5Heading}
              className="font-tt-commons-pro font-bold text-2xl text-greyscale-text-primary tracking-[1.92px] text-center"
            />
            <span className="font-tt-commons-pro text-base font-[450] text-center text-ccc  tracking-[1.28px] leading-6">
              Our team has years of experience
              {isMobile && <br />} working in some of the best web3 companies
            </span>
          </div>

          <div className="flex flex-wrap font-tt-commons-pro gap-y-6 mix-blend-luminosity">
            <div className="basis-1/2 space-y-4">
              <div className="flex items-center justify-center">
                <img src="/images/synthetix.png" alt="" className="w-14 h-14" />
              </div>
              <p className="text-greyscale-text-secondary text-sm font-semibold uppercase leading-[18px]">
                Synthetix
              </p>
            </div>
            <div className="basis-1/2 space-y-4">
              <div className="flex items-center justify-center">
                <img src="/images/curve.png" alt="" className="w-14 h-14" />
              </div>
              <p className="text-greyscale-text-secondary text-sm font-semibold uppercase leading-[18px]">
                Curve Finanace
              </p>
            </div>
            <div className="basis-1/2 space-y-4">
              <div className="flex items-center justify-center">
                <img src="/images/y2k.png" alt="" className="w-14 h-14" />
              </div>
              <p className="text-greyscale-text-secondary text-sm font-semibold uppercase leading-[18px]">
                Y2K Finance
              </p>
            </div>
            <div className="basis-1/2 space-y-4">
              <div className="flex items-center justify-center">
                <img
                  src="/images/horizen-labs.png"
                  alt=""
                  className="w-14 h-14"
                />
              </div>
              <p className="text-greyscale-text-secondary text-sm font-semibold uppercase leading-[18px]">
                HORIZEN LABS
              </p>
            </div>

            <div className="basis-1/2 space-y-4">
              <div className="flex items-center justify-center">
                <img src="/images/polygon.png" alt="" className="w-14 h-14" />
              </div>
              <p className="text-greyscale-text-secondary text-sm font-semibold uppercase leading-[18px]">
                Polygon Labs
              </p>
            </div>

            <div className="basis-1/2 space-y-4">
              <div className="flex items-center justify-center">
                <img src="/images/aevo.png" alt="" className="w-14 h-14" />
              </div>
              <p className="text-greyscale-text-secondary text-sm font-semibold uppercase leading-[18px]">
                Aevo
              </p>
            </div>

            <div className="basis-1/2 space-y-4">
              <div className="flex items-center justify-center">
                <img src="/images/kraken.png" alt="" className="w-14 h-14" />
              </div>
              <p className="text-greyscale-text-secondary text-sm font-semibold uppercase leading-[18px]">
                Kraken
              </p>
            </div>

            <div className="basis-1/2 space-y-4">
              <div className="flex items-center justify-center">
                <img src="/images/y2k.png" alt="" className="w-14 h-14" />
              </div>
              <p className="text-greyscale-text-secondary text-sm font-semibold uppercase leading-[18px]">
                Y2K Finance
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="mix-blend-luminosity absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] rounded-full  h-[600px] w-[700px]">
          <div className="w-full h-full rounded-full relative flex items-center justify-center ">
            <MotionInView variants={varZoomInOut}>
              <div className="orbit">
                <div className="center-image">
                  <div
                    ref={slide5Ref}
                    className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 flex flex-col gap-7 w-[410px]"
                  >
                    <span
                      ref={slide5Heading}
                      className="font-tt-commons-pro font-bold text-[40px] sm:text-[56px] text-greyscale-text-primary tracking-[4.48px] leading-[50px] text-center"
                    />
                    <span className="font-tt-commons-pro text-base font-[450] text-center text-ccc  tracking-[1.28px] leading-[24px]">
                      Our team has years of experience working in some of the
                      best web3 companies
                    </span>
                  </div>
                </div>
                <ul>
                  <li>
                    <div>
                      <img src="/images/horizen-labs.png" alt="" />
                    </div>
                    <p className="text-greyscale-text-secondary pt-28 text-base font-semibold uppercase">
                      HORIZEN LABS
                    </p>
                  </li>
                  {/* <li>
                    <div>
                      <img src="/images/arda.png" alt="" />
                    </div>
                    <p className=" text-greyscale-text-secondary pt-28 text-base font-semibold uppercase">
                      Arda Finanace
                    </p>
                  </li> */}
                  <li>
                    <div>
                      <img src="/images/polygon.png" alt="" />
                    </div>
                    <p className="text-greyscale-text-secondary pt-28 text-base font-semibold uppercase">
                      Polygon Labs
                    </p>
                  </li>
                  <li>
                    <div>
                      <img src="/images/synthetix.png" alt="" />
                    </div>
                    <p className="text-greyscale-text-secondary pt-28 text-base font-semibold uppercase">
                      Synthetix
                    </p>
                  </li>
                  <li>
                    <div>
                      <img src="/images/aevo.png" alt="" />
                    </div>
                    <p className="text-greyscale-text-secondary pt-28 text-base font-semibold uppercase">
                      Aevo
                    </p>
                  </li>
                  <li>
                    <div>
                      <img src="/images/kraken.png" alt="" />
                    </div>
                    <p className=" text-greyscale-text-secondary pt-28 text-base font-semibold uppercase">
                      Kraken
                    </p>
                  </li>
                  <li>
                    <div>
                      <img src="/images/curve.png" alt="" />
                    </div>
                    <p className="text-greyscale-text-secondary pt-28 text-base font-semibold uppercase">
                      Curve Finanace
                    </p>
                  </li>
                  <li>
                    <div>
                      <img src="/images/y2k.png" alt="" />
                    </div>
                    <p className="text-greyscale-text-secondary pt-28 text-base font-semibold uppercase">
                      Y2K Finance
                    </p>
                  </li>
                </ul>
              </div>
            </MotionInView>
          </div>
        </div>
      )}
    </section>
  );
};

export default Slide5;
