import clsx from "clsx";

const BgNoise = ({ className }) => {
  return (
    <div
      className={clsx(
        "pointer-events-none absolute left-0 top-0 h-full w-full select-none bg-noise opacity-80",
        className,
      )}
    />
  );
};

export default BgNoise;
