import { LAUNCH_APP, TRADE_ON } from "../config/external-links";

const Navbar = () => {
  return (
    <div className="h-12 xl:h-16 flex justify-between fixed w-full top-0 z-50 left-0 select-none">
      <div className="h-full flex items-center justify-center px-6 xl:px-4">
        <img alt="logo" src="/icons/logo.svg" width={64} height={26} />
      </div>

      <div className="w-[115px] xl:w-[168px] h-full bg-greyscale-text-primary">
        <a
          target="_blank"
          rel="noreferrer"
          href={LAUNCH_APP}
          className="w-full h-full block"
        >
          <button
            className="w-full h-full text-surface-primary text-sm font-semibold flex flex-col items-center justify-center"
          >
            TRADE
          </button>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
