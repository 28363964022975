import clsx from "clsx";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { useScramble } from "use-scramble";
import { LAUNCH_APP, READ_DOCS } from "../../config/external-links";
import { MotionInView } from "../MotionInView";
import { varFadeInUp } from "../variants";
import { CLOUD_FRONT_URL } from "../../config/constants";
import { BackgroundVideoPlayer } from "../BackgroundVideoPlayer";
import CardStack from "../../pages/CardStack";

const Slide1 = () => {
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const isWideScreen = useMediaQuery({ minWidth: 1800 });

  const { ref: welcomeTextRef, replay: playWelcomeTextRef } = useScramble({
    text: "Welcome Anon",
    scramble: 3,
    speed: 0.4,
  });
  const [heroRef, isHeroInView] = useInView({
    triggerOnce: true,
    delay: 1000,
    trackVisibility: true,
  });

  useEffect(() => {
    if (isHeroInView) {
      playWelcomeTextRef();
    }
  }, [isHeroInView]);

  return (
    <section className="relative h-[844px] xl:h-[1200px] 4xl:h-[1440px]">
      <div className="relative h-[810px] 3xl:h-[863.5px] 4xl:h-[1024px]">
        <div className="h-full w-full">
          <BackgroundVideoPlayer
            webMVideo={
              isMobile
                ? `${CLOUD_FRONT_URL}/videos/slide1-bg-mobile.webm`
                : `${CLOUD_FRONT_URL}/videos/slide1-bg-video.webm`
            }
            mp4Video={
              isMobile
                ? `${CLOUD_FRONT_URL}/videos/slide1-bg-mobile.mp4`
                : `${CLOUD_FRONT_URL}/videos/slide1-bg-video.mp4`
            }
            videoOptions={{
              className: "h-full w-full object-cover",
            }}
          />
        </div>

        <div
          className={clsx(
            "absolute left-1/2 -translate-x-1/2 flex flex-col items-center w-full z-20",
            isMobile ? "top-[320px]" : "top-[252px]"
          )}
        >
          <div ref={heroRef} className="mb-8 px-6 xl:px-0">
            <MotionInView variants={varFadeInUp}>
              <p
                ref={welcomeTextRef}
                className="text-[32px] xl:text-[64px] leading-8 xl:leading-[64px] font-bold text-greyscale-text-primary tracking-[2.56px] xl:tracking-[5.12px] font-the-alpha-day mb-2"
              />
              <p className="text-greyscale-text-primary capitalize text-xs xl:text-[15px] font-normal tracking-[0.96px] xl:tracking-[1.2px] xl:leading-6">
                decentralized perpetual futures exchange on{" "}
                <span className="normal-case">zkSync</span>.
                <br />
                Optimize your yield. Create your own trading pairs. Amplify your
                edge.
              </p>
            </MotionInView>
          </div>

          <MotionInView
            variants={varFadeInUp}
            className="w-full flex flex-col xl:flex-row xl:justify-center gap-4 xl:gap-6 px-6 xl:px-0"
          >
            <a
              target="_blank"
              rel="noreferrer"
              className="rounded-md"
              href={LAUNCH_APP}
            >
              <button
                className="h-10 xl:h-12 w-full xl:w-56 bg-main rounded-md text-greyscale-text-primary font-bold uppercase flex flex-col items-center justify-center"
              >
                LAUNCH APP
              </button>
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              className="rounded-md"
              href={READ_DOCS}
            >
              <button className="h-10 xl:h-12 w-full xl:w-56 bg-transparent border border-greyscale-text-primary rounded-md text-greyscale-text-primary font-bold uppercase">
                Read docs
              </button>
            </a>
          </MotionInView>
        </div>
      </div>

      <div className="h-[213px] xl:h-[601px] 4xl:h-[820px] absolute -bottom-10 select-none flex flex-col gap-2 w-full">
        {!isMobile && (
          <div className="absolute -top-20  left-6">
            <p className="text-greyscale-grey-secondary text-sm tracking-[1.12px] font-bold font-the-alpha-day">
              Powered by
            </p>
            <div className="px-1.5 py-[3px] flex items-center gap-1">
              <img
                src="/icons/pyth-white.svg"
                alt="rfx"
                className="w-3 h-[18px]"
              />
              <p className="text-greyscale-text-primary font-semibold capitalize">
                Pyth network
              </p>
            </div>
          </div>
        )}
        {/* {isMobile ? (
          <img
            src="/images/slide1-bottom-mobile.png"
            alt="rfx"
            className="w-full h-full"
          />
        ) : (
          <div className="w-full h-full relative flex items-end">
            <div className="slide1-bottom-left 4xl:!h-[642px]">
              <img
                src={
                  isWideScreen
                    ? `/images/slide1-bottom-left-wide.png`
                    : `/images/slide1-bottom-left.png`
                }
                alt="rfx"
                className={`h-full w-full`}
              />
            </div>
            <div className={`slide1-bottom-center`}>
              <img
                src={
                  isWideScreen
                    ? `/images/slide1-bottom-center-wide.png`
                    : `/images/slide1-bottom-center.png`
                }
                alt="rfx"
                className={`h-full w-full`}
              />
            </div>
            <div className="slide1-bottom-right 4xl:!h-[642px]">
              <img
                src={
                  isWideScreen
                    ? `/images/slide1-bottom-right-wide.png`
                    : `/images/slide1-bottom-right.png`
                }
                alt="rfx"
                className={`h-full w-full `}
              />
            </div>
          </div>
        )} */}

        <div className="absolute  4xl:top-0 w-full h-full">
          <CardStack />
        </div>
      </div>
    </section>
  );
};

export default Slide1;
