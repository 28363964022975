import { ReactComponent as Discord } from "../assets/social-icons/discord.svg";
import { ReactComponent as Twitter } from "../assets/social-icons/twitter.svg";
import { ReactComponent as Telegram } from "../assets/social-icons/telegram.svg";
import HoveredStateIcon from "./HoveredStateIcon";
import { socialLink } from "../config/external-links";
import { clsx } from "clsx";

const SocialIcons = ({ className }) => {
  return (
    <div className={clsx("flex flex-col gap-3", className)}>
      <a
        target="_blank"
        rel="noreferrer"
        href={socialLink.TWITTER_URL}
        className="hover:[&_svg_path]:fill-[#fff] duration-200"
      >
        <Twitter />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={socialLink.TELEGRAM_URL}
        className="hover:[&_svg_path]:fill-[#08c] duration-200"
      >
        <Telegram />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={socialLink.DISCORD_URL}
        className="hover:[&_svg_path]:fill-[#5865f2] duration-200"
      >
        <Discord />
      </a>

      {/* <a
        target="_blank"
        rel="noreferrer"
        href={socialLink.GITHUB_URL}
        className="hover:[&_svg_path]:fill-[#b8b9bf] duration-200"
      >
        <Github />
      </a> */}
      <a rel="noreferrer" target="_blank" href={socialLink.MIRROR_URL}>
        <HoveredStateIcon
          icon={<img alt="" className="w-6 h-6" src="/icons/mirror.svg" />}
          hoverIcon={
            <img alt="" className="w-6 h-6" src="/icons/mirror-colored.svg" />
          }
        />
      </a>
    </div>
  );
};

export default SocialIcons;
