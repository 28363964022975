import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useMediaQuery } from "react-responsive";
import { ToastContainer } from "react-toastify";
import "./App.css";
import SocialIcons from "./components/SocialIcons";
import TokenTicker from "./components/TokenTicker";
import { Cookie3Provider } from "./lib/analytics/provider";
import { HomePage } from "./pages/HomePage";

export const queryClient = new QueryClient();

function App() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Cookie3Provider>
      <QueryClientProvider client={queryClient}>
        <main className="relative text-center hide-scroll font-tt-commons-pro">
          {!isMobile && (
            <>
              <div className="fixed bottom-0 left-0 flex-1 z-50 w-full bg-surface-primary border-y border-border-primary">
                <div className="relative overflow-hidden">
                  <div className="absolute left-0 z-20 h-full w-[82px] [background:_linear-gradient(90deg,_rgba(6,_6,_6,_0.30)_0%,_rgba(6,_6,_6,_0.80)_0.01%,_rgba(0,_0,_0,_0.00)_117.31%);]" />
                  <div className="absolute right-0 z-20 h-full w-[82px] rotate-180 [background:_linear-gradient(90deg,_rgba(6,_6,_6,_0.30)_0%,_rgba(6,_6,_6,_0.80)_0.01%,_rgba(0,_0,_0,_0.00)_117.31%);]" />
                  <TokenTicker />
                </div>
              </div>

              <div className="fixed z-50 top-[640px] right-8">
                <SocialIcons />
              </div>
            </>
          )}

          <HomePage />
        </main>
      </QueryClientProvider>
      <ToastContainer />
    </Cookie3Provider>
  );
}

export default App;
